import styled from 'styled-components';
import PropTypes from 'prop-types';

const getSize = size => {
  switch (size) {
    case 'lg':
      return 3.2;
    default:
      return 1.2;
  }
};

const Spinner = styled.div`
  display: inline-block;
  margin-right: 0.5rem;
  width: ${props => getSize(props.size || '')}rem;
  height: ${props => getSize(props.size | '')}rem;
  border: 3px solid ${props => props.theme.colors.spinner.border[props.variant || 'light']};
  border-radius: 50%;
  border-top-color: ${props => props.theme.colors.white};
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
`;

Spinner.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.oneOf(['dark', 'light']),
};

export default Spinner;
